const Colors = {
  green: "#0c6135",
  primary: "#4ab43e",
  // lightprimary: '#4ab43e',
  tableGradient: "linear-gradient(182.15deg, #0C6135 -77.31%, #93D222 97.38%)",
  // primaryGradient: "linear-gradient(to right, #0c6135, #147836, #268e32, #3fa528, #5bbc0f)",
  primaryGradient:
    "linear-gradient(94.06deg, #4CAF4F -1.21%, #6BBD6D 50.75%, #358437 102.71%)",
  // primaryGradient2: "linear-gradient(to bottom, #0c6135, #147836, #268e32, #3fa528, #5bbc0f)",
  primaryGradient2: "linear-gradient(180deg, #0C6135 0%, #5BBC0F 100%)",
  secondary: "#0f1b24",
  textSecondary: "#14212a",
  cloudyGrey: "#666666",
  darkGrey: "#333333",
  charcoalGrey: "#323B4B",
  Martini: "#A3A3A3",
  grey: "#b3b3b3",
  greyCloud: "#B2B5BA",
  cloud: "#c2c2c2",
  iron: "#d9d9d9",
  lightGrey: "#797979",
  greyShade: "#959595",
  smokeyGrey: "#747474",
  water: "#DBE1EB",
  dawnPink: "#F8E4E4",
  white: "#ffffff",
  whiteSmoke: "#F3F3F3",
  milkWhite: "#FAFBFC",
  flashWhite: "#EFF0F6",
  lightPink: "#FEF8F8",
  mercury: "#DFE5EB",
  iceBerg: "#D2F2FF",
  aliceBlue: "#EAF9FF",
  feta: "#EEFBEE",
  yellow: "#FFF500",
  softPeach: "#ECEDF4",
  black: "#222222",
  mirage: "#15142A",
  blueWhale: "#082B43",
  darkCerulean: "#094771",
  smokyBlack: "#0707074d",
  blackShade: "#0f1b24de",
  bluishCyan: "#27ADDF",
  danger: "#d32f2f",
  success: "#79AA5A",
  whatsapp: "#075e54",
  facebook: "#3b5999",
  linkedin: "#0A66C2",
  twitter: "#55acee",
  instagram: "#e4405f",
  youtube: "#cd201f",
  blue: "#25ABE1",
  invoice: "#ed893e",
  urgent: "#E4432E",
  fullTime: "#65A8E1",
  partTime: "#9747FF",
  borderIron: "#EAEAEA",
  facebook2: "#316FF6",
  instagram2: "#FA7E1E",
  linkedin2: "#0077B5",
  lightGreen: "#5BBC0F",
  red:"#ff0004"
};

export default Colors;
