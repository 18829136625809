const routes = {
  requestBuyerId: 'customers/requestBuyerId',
  requestBuyerIdAgain: 'customers/requestBuyerIdAgain',
  getCustomerDropDown: 'customers/dropdown',
  getCustomerQueue: 'customers/queue',
  getCustomerDetail: 'customers/details',
  getBuyerIdDetail: 'customers/buyerId/details',
  getBuyerIdByAuctionId: 'customers/request/details',
  verifyFinance: 'customers/verifyFinance',
  allocateBuyerId: 'customers/allocateBuyerId',
  deallocateBuyerId: 'customers/deallocateBuyerId',
  changeAllocationStatus: 'customers/changeAllocationStatus',
  getMessages: 'customers/messages',
  sendMessage: 'customers/sendMessage',
  getCustomerBooking: 'customers/booking',
  getCustomerBuyerId: 'customers/buyerIds',
  deleteId:'customers/removeAuctionHouse',
  getGalaxyCustomerList:'customers/galaxy',
  CreateMemo:'customers/sendMemo',
  CreateNotifcation:'customers/sendNotifications',
  ChangeBranch:'customers/updateBranch',
  ChangeType:'customers/updateTrustedStatus',
  CreateSubCustomer:'users/addSubCustomer',
  getSubCustomerList:'users/subCustomers',
  SelectCustomer:'vehicleBookings/update',
  disableNoti:'users/update/notiAlerts',
  disableVccGP:'users/update/gpVccAlerts',

};

export default routes