import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Colors from 'assets/Style/Colors';
import { useAuth } from 'context/UseContext';
import { Box } from '@mui/material';

const useStyle = makeStyles({
  step: {
    flex: 1,
    display: 'flex',
    gap: '20px',
    alignItem: 'center',
    justifyContent: 'space-between',
    '& .MuiStepLabel-label': {
      color: `${Colors.charcoalGrey} !important`,
      fontSize: { md: '16px' },
    },
  },
});

function BuyerIdDashboard() {
  const [renderState, setRenderState] = useState();
  const { user } = useAuth();

  var METABASE_SITE_URL = 'https://analytics.galaxyshipping.com';

  let iframeUrl =
    METABASE_SITE_URL +
    '/embed/dashboard/' +
    localStorage.getItem('buyerIdToken') +
    '#bordered=false&titled=true';
  useEffect(() => {
    if (localStorage.getItem('vccToken')) {
      setRenderState(localStorage.getItem('vccToken'));
    }
  }, []);

  return (
    <Fragment>
      {user?.user_type != 'C' && renderState && (
        <Box sx={{ height: '100vh' }}>
          <iframe
            title='buyerIdIframe'
            src={iframeUrl}
            frameBorder={0}
            width={'100%'}
            height={'100%'}
            allowTransparency
          />
        </Box>
      )}
    </Fragment>
  );
}

export default BuyerIdDashboard;
