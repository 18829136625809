import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton, Divider } from "@mui/material";
import { PowerSettingsNew } from "@mui/icons-material";
import { useAuth } from "context/UseContext";
import Colors from "assets/Style/Colors";
import { FontFamily, GalaxyUsedCarLogo, IdCardIcon, Images } from "assets";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { Link, json, useLocation, useNavigate } from "react-router-dom";
import ConfirmationDialog from "components/Dialog/ConfirmationDialog";
import MenuIcon from '@mui/icons-material/Menu';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import moment from "moment";
import AuthServices from "services/Auth";
import { ErrorToaster, SuccessToaster } from "components/Toaster";
import SystemServices from "services/System";




function Header({ onClick }) {
	const { user, userLogout } = useAuth();
	const navigate = useNavigate();
	const location = useLocation();

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [notifications, setNotifications] = useState([])
	const [notificationCount, setNotificationCount] = useState(0)


	// *For Get Side Navigation
	const getNotifications = async () => {
		try {
			const { data } = await SystemServices.getNotifications()
			console.log(data, 'onlynotfi');

			setNotificationCount(data?.unreadNotifications)

			setNotifications(data?.notifications?.rows)
			console.log(data?.notifications?.rows);
		} catch (error) {
			ErrorToaster(error)
		}
	}

	const open = Boolean(anchorEl);
	const handleClick = (event) => {


		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {

		setAnchorEl(false);
	};

	// *For Dialog Box
	const [confirmationDialog, setConfirmationDialog] = useState(false);
	const handleLogout = async (formData) => {
		let obj = {
			fcm_token: localStorage.getItem('fcmToken')
		}
		try {
			const { message } = await AuthServices.handleLogout(obj);




		}

		catch (error) {
			ErrorToaster(error);
		}
	}
	const notificationSeen = async (e, id, route) => {
		e.preventDefault()
		let obj = {
			notification_id: id
		}
		try {
			const { message } = await SystemServices.notificationSeen(obj);
			handleClose()
			console.log(route);
			navigate(route ? route : '/dashboard')



		}

		catch (error) {
			ErrorToaster(error);
		}
	}

	useEffect(() => {

		getNotifications()


	}, [location?.pathname])

	useEffect(() => {

		getNotifications()


	}, [])


	return (
		// <Box
		// 	sx={{
		// 		display: "flex",
		// 		alignItems: "center",
		// 		justifyContent: "space-between",
		// 		bgcolor: Colors.white,
		// 		px: 3,
		// 		py: 1,
		// 		boxShadow: "0px 4px 8px 0px #9B9B9B1A",
		// 	}}
		// >
		// 	{/* ========== Confirmation Dialog ========== */}
		// 	<ConfirmationDialog
		// 		open={confirmationDialog}
		// 		onClose={() => setConfirmationDialog(false)}
		// 		message={"Are you sure you want to logout?"}
		// 		action={() => {
		// 			setConfirmationDialog(false);
		// 			userLogout();
		// 			handleLogout();
		// 		}}
		// 	/>
		// 	<Box display={'flex'} alignItems={'center'} gap={2} >
		// 		<MenuIcon onClick={onClick} sx={{ fontSize: '35px', cursor: 'pointer', color: Colors.charcoalGrey }} />
		// 		<Link to={"/"}>
		// 			{/* <GalaxyUsedCarLogo /> */}

		// 			<Box component={"img"} src={Images.mainLogo} sx={{ height: "50px" }} />
		// 		</Link>
		// 	</Box>
		// 	<Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
		// 		<IdCardIcon />

		// 		<Typography
		// 			variant="body2"
		// 			sx={{ color: Colors.charcoalGrey, fontFamily: FontFamily.NunitoRegular }}
		// 		>
		// 			{user?.user_type === "A"
		// 				? "Admin ID : "
		// 				: user?.user_type === "S"
		// 					? "Staff ID : "
		// 					: "Customer ID"}
		// 		</Typography>
		// 		<Typography variant="body2" sx={{ color: Colors.charcoalGrey, fontWeight: 700 }}>
		// 			{user?.ref_id}
		// 		</Typography>
		// 		<Badge badgeContent={notificationCount} color="primary">
		// 			<NotificationsIcon sx={{ cursor: 'pointer' }} id="basic-button"
		// 				aria-controls={open ? 'basic-menu' : undefined}
		// 				aria-haspopup="true"
		// 				aria-expanded={open ? 'true' : undefined}
		// 				onClick={handleClick} color="action" />
		// 		</Badge>
		// 		<Menu
		// 			id="basic-menu"
		// 			anchorEl={anchorEl}
		// 			open={open}
		// 			onClose={handleClose}
		// 			MenuListProps={{
		// 				'aria-labelledby': 'basic-button',
		// 			}}
		// 			PaperProps={{
		// 				style: {
		// 					width: '400px',
		// 					height: '500px',
		// 					left: '100px !important', // Set the desired width here,
		// 					borderRadius: '25px !important'
		// 				},
		// 			}}

		// 		>
		// 			<Typography variant="h5" sx={{ fontWeight: 700, ':hover': { color: Colors.primary }, m: 1, ml: 2 }}>
		// 				Notifications
		// 			</Typography>
		// 			{notifications?.map((item, index) => {
		// 				let link = JSON.parse(item?.data)



		// 				return (
		// 					<React.Fragment key={index}>
		// 						<Link to={link?.route ? link?.route : '/dashboard'}>	<MenuItem onClick={handleClose}>
		// 							<Box sx={{ display: 'flex', gap: '5px' }}>
		// 								<Box sx={{ width: '45px', height: '45px', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#1890ff1a', gap: '5px' }}>
		// 									<InfoOutlinedIcon sx={{ fontSize: '30px', color: Colors.primary }} />
		// 								</Box>

		// 								<Box sx={{ whiteSpace: "break-spaces" }}>
		// 									{true && (
		// 										<Typography variant="body2">
		// 											<b>{item.content}</b>
		// 										</Typography>
		// 									)}
		// 									{true && (
		// 										<Typography
		// 											variant="caption"
		// 											sx={{ color: Colors.grey }}
		// 										>
		// 											{moment(item?.created_at).format("DD/MM/YYYY, hh:mm A")}
		// 										</Typography>
		// 									)}
		// 								</Box>
		// 							</Box>
		// 						</MenuItem>
		// 						</Link>
		// 						<Divider sx={{ backgroundColor: '#cccccc' }} />
		// 					</React.Fragment>
		// 				);
		// 			})}

		// 			{/* <MenuItem onClick={handleClose}>
		// 				<Box sx={{ display: 'flex', gap: '5px' }}>
		// 					<Box sx={{ width: '45px', height: "45px", borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#1890ff1a', gap: '5px' }}>
		// 						<InfoOutlinedIcon sx={{ fontSize: '30px', color: Colors.primary }} />
		// 					</Box>

		// 					<Box sx={{ whiteSpace: "break-spaces" }}>

		// 						{true && (
		// 							<Typography variant="body2">
		// 								<b>{("message")}</b> sadasd
		// 							</Typography>
		// 						)}
		// 						{true && (
		// 							<Typography
		// 								variant="caption"
		// 								sx={{ color: Colors.grey }}
		// 							>
		// 								{moment().format("DD/MM/YYYY, hh:mm A")}
		// 							</Typography>
		// 						)}

		// 					</Box>

		// 				</Box>
		// 			</MenuItem> */}

		// 		</Menu>
		// 		<IconButton onClick={() => setConfirmationDialog(true)}>
		// 			<PowerSettingsNew sx={{ color: Colors.charcoalGrey }} />
		// 		</IconButton>
		// 	</Box>
		// </Box>

	
	< Box sx = {{ position: 'absolute', right: 30, top: 20, cursor: 'pointer', zIndex: 1000 }}  >
			<Badge badgeContent={notificationCount} color="error">
				<NotificationsIcon sx={{ cursor: 'pointer', color: Colors.primary }} id="basic-button"
					aria-controls={open ? 'basic-menu' : undefined}
					aria-haspopup="true"
					aria-expanded={open ? 'true' : undefined}
					onClick={handleClick} color="action" />
			</Badge>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
				PaperProps={{
					style: {
						width: '400px',
						height: '500px',
						left: '100px !important', // Set the desired width here,
						borderRadius: '25px !important'
					},
				}}

			>
				<Typography variant="h5" sx={{ fontWeight: 700, ':hover': { color: Colors.primary }, m: 1, ml: 2 }}>
					Notifications
				</Typography>
				{notifications?.length > 0 && notifications?.map((item, index) => {

					let link = JSON.parse(item?.data)



					return (
						<React.Fragment key={index}>
							<Box >
								<Link to={link?.route ? link?.route : '/dashboard'}>
									<MenuItem sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={handleClose}>

										<Box sx={{ display: 'flex', gap: '5px', }} onClick={(e) => notificationSeen(e, item?.id, link?.route)}>
											<Box sx={{ width: '45px', height: '45px', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#1890ff1a', gap: '5px' }}>
												<InfoOutlinedIcon sx={{ fontSize: '30px', color: Colors.primary }} />
											</Box>

											<Box sx={{ whiteSpace: "break-spaces" }}>
												{true && (
													<Typography color={"black"} variant="body2">
														<b>{item.content}</b>
													</Typography>
												)}
												{true && (
													<Typography
														variant="caption"
														sx={{ color: Colors.grey }}
													>
														{moment(item?.created_at).format("DD/MM/YYYY, hh:mm A")}
													</Typography>
												)}
											</Box>
										</Box>
										{!item?.is_read && <Box sx={{ width: '8px', height: "8px", borderRadius: '50%', backgroundColor: Colors.primary }}></Box>}

									</MenuItem>
								</Link>
							</Box>
							<Divider sx={{ backgroundColor: '#cccccc' }} />
						</React.Fragment>
					);
				})}

				{/* <MenuItem onClick={handleClose}>
						<Box sx={{ display: 'flex', gap: '5px' }}>
							<Box sx={{ width: '45px', height: "45px", borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#1890ff1a', gap: '5px' }}>
								<InfoOutlinedIcon sx={{ fontSize: '30px', color: Colors.primary }} />
							</Box>

							<Box sx={{ whiteSpace: "break-spaces" }}>

								{true && (
									<Typography variant="body2">
										<b>{("message")}</b> sadasd
									</Typography>
								)}
								{true && (
									<Typography
										variant="caption"
										sx={{ color: Colors.grey }}
									>
										{moment().format("DD/MM/YYYY, hh:mm A")}
									</Typography>
								)}

							</Box>

						</Box>
					</MenuItem> */}

			</Menu>
		</Box >
	);
}

export default Header;
