import { post, get, patch,deleted } from 'services';
import routes from './routes';

const CustomerServices = {
  requestBuyerId: async (obj) => {
    const data = await post(routes.requestBuyerId, obj);
    return data;
  },
  CreateMemo: async (obj) => {
    const data = await post(routes.CreateMemo, obj);
    return data;
  },
  CreateNotifcation: async (obj) => {
    const data = await post(routes.CreateNotifcation, obj);
    return data;
  },
  CreateSubCustomer: async (obj) => {
    const data = await post(routes.CreateSubCustomer, obj);
    return data;
  },
  ChangeBranch: async (obj) => {
    const data = await patch(routes.ChangeBranch, obj);
    return data;
  },
  SelectCustomer: async (obj) => {
    const data = await patch(routes.SelectCustomer, obj);
    return data;
  },
  ChangeType: async (obj) => {
    const data = await patch(routes.ChangeType, obj);
    return data;
  },
  requestBuyerIdAgain: async (obj) => {
    const data = await post(routes.requestBuyerIdAgain, obj);
    return data;
  },
  deleteId: async (params) => {
    const data = await deleted(routes.deleteId, params);
    return data;
  },
  getCustomerDropDown: async (params) => {
    const data = await get(routes.getCustomerDropDown, params);
    return data;
  },
  getSubCustomerList: async (params) => {
    const data = await get(routes.getSubCustomerList, params);
    return data;
  },
  getGalaxyCustomerList: async (params) => {
    const data = await get(routes.getGalaxyCustomerList, params);
    return data;
  },
  getCustomerQueue: async (params) => {
    const data = await get(routes.getCustomerQueue, params);
    return data;
  },
  getCustomerDetail: async (params) => {
    const data = await get(routes.getCustomerDetail, params);
    return data;
  },
  getBuyerIdDetail: async (params) => {
    const data = await get(routes.getBuyerIdDetail, params);
    return data;
  },
  getBuyerIdByAuctionId: async (params) => {
    const data = await get(routes.getBuyerIdByAuctionId, params);
    return data;
  },
  verifyFinance: async (obj) => {
    const data = await post(routes.verifyFinance, obj);
    return data;
  },
  allocateBuyerId: async (obj) => {
    const data = await post(routes.allocateBuyerId, obj);
    return data;
  },
  deallocateBuyerId: async (obj) => {
    const data = await post(routes.deallocateBuyerId, obj);
    return data;
  },
  changeAllocationStatus: async (obj) => {
    const data = await patch(routes.changeAllocationStatus, obj);
    return data;
  },
  getMessages: async (params) => {
    const data = await get(routes.getMessages, params);
    return data;
  },
  sendMessage: async (obj) => {
    const data = await post(routes.sendMessage, obj);
    return data;
  },
  getCustomerBooking: async (params) => {
    const data = await get(routes.getCustomerBooking, params);
    return data;
  },
  getCustomerBuyerId: async (params) => {
    const data = await get(routes.getCustomerBuyerId, params);
    return data;
  },
  disableNotifications: async (obj) => {
    const data = await patch(routes.disableNoti, obj);
    return data;
  },
  disableVccGP: async (obj) => {
    const data = await patch(routes.disableVccGP, obj);
    return data;
  },
}

export default CustomerServices