import ExportContainerInvoice from "pages/Dashboard/Export/ContainerInvoice";
import PaymentVoucher from "pages/Dashboard/Finance/UpdatePaymentVoucher";
import ReceiptVoucher from "pages/Dashboard/Finance/UpdateReceiptVoucher";
import BuyACar from "pages/Website/BuyACar";
import ClientInvoice from "pages/Website/ClientInvoice/ClientInvoice";
import ExportVehicleInvoice from "pages/Website/ClientInvoice/ExportVehicleInvoice";
import FundTransferVoucher from "pages/Website/ClientInvoice/FundTransferVoucher";
import TopUpVoucher from "pages/Website/ClientInvoice/TopUpVoucher";
import VehicleInvoice from "pages/Website/ClientInvoice/VehicleInvoice";
import Invoice from "pages/Website/Invoice";
import React, { lazy } from "react";

// Lazy loading components
const Home = lazy(() => import("pages/Website/Home"));
const About = lazy(() => import("pages/Website/About"));
const Contact = lazy(() => import("pages/Website/Contact"));
const Service = lazy(() => import("pages/Website/Services"));
const Quote = lazy(() => import("pages/Website/Quote"));
const Blogs = lazy(() => import("pages/Website/Blogs"));
const BlogDetail = lazy(() => import("pages/Website/Blogs/Detail"));
const Career = lazy(() => import("pages/Website/Career"));
const CareerDetail = lazy(() => import("pages/Website/Career/Detail"));
// Public routes array
const PublicRoutes = [
  {
    path: "/",
    component: <Home />,
  },
  {
    path: "/about",
    component: <About />,
  },
  {
    path: "/contact",
    component: <Contact />,
  },
  {
    path: "/services",
    component: <Service />,
  },
  {
    path: "/quote",
    component: <Quote />,
  },
  {
    path: "/blogs",
    component: <Blogs />,
  },
  {
    path: "/blog/:id",
    component: <BlogDetail />,
  },
  {
    path: "/careers",
    component: <Career />,
  },
  {
    path: "/career/:id",
    component: <CareerDetail />,
  },
  {
    path: "/buycar",
    component: <BuyACar />,
  },
  {
    path: "/invoice-preview/:id",
    component: <Invoice />,
  },
  {
    path: "/topup-preview/:id",
    component: <TopUpVoucher />,
  },
  {
    path: "/receipt-voucher-preview/:id",
    component: <ReceiptVoucher />,
  },
  {
    path: "/payment-voucher-preview/:id",
    component: <PaymentVoucher />,
  },
  {
    path: "/fund-transfer-voucher-preview/:id",
    component: <FundTransferVoucher />,
  },
  {
    path: "/vehicle-invoice-preview/:id",
    component: <VehicleInvoice />,
  },
  {
    path: "/client-invoice-preview/:id",
    component: <ClientInvoice />,
  },
  {
    path: "/export-vehicle-preview/:id",
    component: <ExportVehicleInvoice />,
  },
  {
    path: "/export-container-invoice-preview/:id",
    component: <ExportContainerInvoice />,
  },
];

export default PublicRoutes;
